import * as React from "react"
//import theme from "../styles/themeOptions"
//import { ThemeProvider, styled } from '@mui/material/styles';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { Helmet } from "react-helmet"

import CssBaseline from '@mui/material/CssBaseline';
import ButtonAppBar from '../components/buttonAppBar'



const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#25294A',
      dark: '#202442',
      light: '#2E325A',
    },
    secondary: {
      main: '#e2308e',
      light: '#e64598',
      dark: '#8c1d58',
    },
    background: {
      default: '#25294A',
      paper: '#2E325A',
    },
  },
  typography: {
    fontFamily: '"M PLUS Rounded 1c", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 16,
  },
});

// styles
const pageStyles = {
  padding: 0,
  margin: 0,
}
const mainContent = {
  padding: "20px",
  color: "white"
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Khao Manee - Homepage</title>
        <link rel="canonical" href="http://khao.so" />
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap" rel="stylesheet"/>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
        <meta name="description" content="This is our home page! We plan to build the biggest cat token in the world!" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ButtonAppBar />
        <div style={mainContent}>
          <h1 style={headingStyles}>
            Welcome to the Khao Manee page! 🎉🎉🎉
          </h1>
        </div>
      </ThemeProvider>
    </main>
  )
}

export default IndexPage
